import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 4×8\\@70%1RM`}</p>
    <p>{`Snatch Grip RDL’s 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP:`}</p>
    <p>{`12-Power Snatch (95/65)`}</p>
    <p>{`12-Bar Facing Burpees`}</p>
    <p>{`36-Double Unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Friday will be our 12 Days of Christmas wod at 9:00 & 10:30. All
other classes are cancelled.`}</em></strong></p>
    <p>{`*`}<strong parentName="p"><em parentName="strong">{`We will be closed Saturday, Christmas Day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      